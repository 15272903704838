<template>
  <div>
    <Toolbar class="mb-4">
      <template #left>
        <Button
          icon="pi pi-times"
          class="p-button-danger mr-2"
          @click="$router.push('/admin/users')"
        />
        <Button icon="pi pi-print" @click="printPage()" label="Print" />

        <Button
          class="p-button-help ml-2"
          @click="
            $router.push('/admin/users/years/' + id + '/' + $byYear(month))
          "
          label="Year Report"
          v-if="month"
        />
      </template>

      <template #right>
        <Calendar v-model="month" view="month" dateFormat="mm/yy" />
      </template>
    </Toolbar>
    <div id="print">
      <div v-for="(item, index) in list" :key="index">
        <DataTable
          :ref="'dt_' + index"
          :value="item"
          class="p-datatable-gridlines p-datatable-sm"
          showGridlines
          :class="index > 0 ? 'hideHead' : ''"
          dataKey="day"
          :rowHover="true"
        >
          <template #empty>Keine Daten</template>
          <template #loading>Daten werden geladen, bitte warten... </template>
          <Column field="day" header="Datum">
            <template #body="slotProps">
              <Button
                type="button"
                icon="pi pi-sign-in"
                v-if="!slotProps.data.id"
                @click="addWorkDay(slotProps.data.day)"
                class="p-button-warning ml-2 p-button-rounded"
              ></Button>
              {{ slotProps.data.day }}
            </template>
          </Column>
          <Column field="tag" header="Tag" />
          <Column field="signIn" header="Beginn">
            <template #body="slotProps">
              <span
                v-if="slotProps.data.id"
                @click="
                  updateData('signIn', slotProps.data.id, slotProps.data.signIn)
                "
              >
                {{ slotProps.data.signIn ? slotProps.data.signIn : "--" }}
              </span>
            </template>
          </Column>
          <Column field="signOut" header="Ende">
            <template #body="slotProps">
              <span
                v-if="slotProps.data.id"
                @click="
                  updateData(
                    'signOut',
                    slotProps.data.id,
                    slotProps.data.signOut
                  )
                "
              >
                {{ slotProps.data.signOut ? slotProps.data.signOut : "--" }}
              </span>
            </template>
          </Column>
          <Column field="pause" header="Pause">
            <template #body="slotProps">
              <span
                v-if="slotProps.data.id"
                @click="
                  updateData(
                    'pause',
                    slotProps.data.id,
                    slotProps.data.pause || 0
                  )
                "
              >
                {{ slotProps.data.pause || 0 }}
              </span>
            </template>
          </Column>
          <Column field="total" header="Gesamt">
            <template #body="slotProps">{{ getDiff(slotProps.data) }}</template>
          </Column>
          <Column field="workHours" header="Std./Woche" />
          <Column field="publicHolidays" header="Feiertage">
            <template #body="slotProps">
              <span
                v-if="slotProps.data.id"
                @click="
                  updateData(
                    'publicHolidays',
                    slotProps.data.id,
                    slotProps.data.publicHolidays || 0
                  )
                "
              >
                {{ slotProps.data.publicHolidays || 0 }}
              </span>
            </template>
          </Column>
          <Column field="plusOrMines" header="+ / -" />
          <Column field="holydays" :header="'Urlaub/' + user.holydays + 'Tag'">
            <template #body="slotProps">
              <span
                v-if="slotProps.data.id"
                @click="
                  updateData(
                    'holydays',
                    slotProps.data.id,
                    slotProps.data.holydays || 0
                  )
                "
              >
                {{ slotProps.data.holydays || 0 }}
              </span>
            </template>
          </Column>
          <Column field="sickdays" header="Krank/Tag">
            <template #body="slotProps">
              <span
                v-if="slotProps.data.id"
                @click="
                  updateData(
                    'sickdays',
                    slotProps.data.id,
                    slotProps.data.sickdays || 0
                  )
                "
              >
                {{ slotProps.data.sickdays || 0 }}
              </span>
            </template>
          </Column>

          <ColumnGroup type="footer">
            <Row>
              <Column footer="" />
              <Column footer="" />
              <Column footer="" />
              <Column footer="" />
              <Column
                :footer="
                  item.map((el) => el.pause).reduce((a, b) => a + b, 0) + ' Min'
                "
                :footerStyle="{ 'text-align': 'center' }"
              />
              <Column
                :footer="getTotal(item)"
                :footerStyle="{ 'text-align': 'center' }"
              />
              <Column :footerStyle="{ 'text-align': 'center' }">
                <template #footer>
                  <span> {{ getWorkHoursToWeak(item) }} Std. </span>
                </template>
              </Column>
              <Column
                :footer="
                  item.map((el) => el.publicHolidays).reduce((a, b) => a + b, 0)
                "
                :footerStyle="{ 'text-align': 'center' }"
              />
              <Column
                :footer="getMinOrPlus(item)"
                :footerStyle="{ 'text-align': 'center' }"
              />
              <Column :footer="0" :footerStyle="{ 'text-align': 'center' }" />
              <Column :footer="0" :footerStyle="{ 'text-align': 'center' }" />
            </Row>
          </ColumnGroup>
        </DataTable>
        <DataTable
          v-if="list.length == index + 1"
          :ref="'dt_' + list.length"
          :value="[
            {},
            {
              day: 'Ende Monat',
              tag: null,
              signIn: null,
              signOut: null,
              pause: null,
              workHours: getMonthWorkHours(),
              total: null,
              plusOrMines: getPlusOrMines(),
              holydays: getHolydays(),
              sickdays: getSickdays(),
            },
            {},
          ]"
          class="p-datatable-gridlines p-datatable-sm"
          :class="index > 0 ? 'hideHead' : ''"
          dataKey="day"
          :rowHover="true"
          showGridlines
        >
          <template #empty>Keine Daten</template>
          <template #loading>Daten werden geladen, bitte warten... </template>
          <Column field="day" header="Datum" />
          <Column field="tag" header="Tag" />
          <Column field="signIn" header="Beginn" />
          <Column field="signOut" header="Ende" />
          <Column field="pause" header="Pause" />
          <Column field="total" header="Gesamt" />
          <Column field="workHours" header="Std./Woche" />
          <Column field="publicHolidays" header="Feiertage" />
          <Column field="plusOrMines" header="+ / -" />
          <Column
            field="holydays"
            :header="'Urlaub/' + user.holydays + 'Tag'"
          />
          <Column field="sickdays" header="Krank/Tag" />
        </DataTable>
      </div>
    </div>

    <Dialog :visible.sync="display" id="xxsxs">
      <div class="form-card">
        <div class="mb-3">
          <label for="name" class="form-label required">
            value
            <span>*</span>
          </label>
          <div
            class="form-check"
            v-if="
              editValue == 'holydays' ||
              editValue == 'sickdays' ||
              editValue == 'publicHolidays'
            "
          >
            <input
              class="form-check-input"
              type="checkbox"
              v-model.number="value"
              :value="1"
              id="flexCheckChecked"
            />
            <label class="form-check-label" for="flexCheckChecked">
              Checked
            </label>
          </div>
          <Calendar
            id="time12"
            v-model="value"
            :timeOnly="true"
            hourFormat="24"
            v-else-if="editValue == 'signIn' || editValue == 'signOut'"
          />

          <input
            v-else
            type="number"
            class="form-control"
            id="name"
            v-model.number="value"
            required
          />
        </div>
        <div class="mb-3 text-center">
          <Button
            label="Bearbeiten"
            icon="pi pi-pencil"
            class="ml-2 p-button-info"
            @click="AddupdateData()"
          />
        </div>
      </div>
    </Dialog>
    <Dialog :visible.sync="displayWeak">
      <div class="form-card">
        <div class="mb-3">
          <label for="name" class="form-label required">
            value
            <span>*</span>
          </label>
          <input
            type="number"
            class="form-control"
            id="name"
            v-model.number="valueWeak"
            required
          />
        </div>
        <div class="mb-3 text-center">
          <Button
            label="Bearbeiten"
            icon="pi pi-pencil"
            class="ml-2 p-button-info"
            @click="addWeakDay()"
            v-if="valueWeak && valueWeak > 0"
          />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      list: [],
      month: null,
      id: null,
      daysList: [],
      editValue: null,
      value: null,
      editId: null,
      display: false,
      workHours: [],
      weakIdx: null,
      displayWeak: false,
      valueWeak: null,
      user: {
        workHours: 0,
        holydays: 0,
      },
    };
  },
  methods: {
    getWorkHoursToWeak(list) {
      const workHoursByMIntes = Number(this.user.workHours) * 60;
      // console.log('workHoursByMIntes => 30 * 60',workHoursByMIntes)
      const day = workHoursByMIntes / 7;
      // console.log('day => workHoursByMIntes/7',day)

      const total = Number(list.length) * day;
      // console.log('total => length*day',total);

      const hours = Math.floor(total / 60);
      // console.log('hours',hours);

      const minutes = Math.trunc(total % 60);
      // console.log('minutes',minutes);

      return this.$timeByHours(hours, minutes);
    },
    addWorkDay(day) {
      const duration = moment(day, "DD-MM-YYYY");
      // console.log(duration);
      const date = duration.format("DD-MM-YYYY");
      const month = duration.format("MM-YYYY");
      const signIn = duration.format("H:mm");
      const signOut = duration.format("H:mm");

      this.$http
        .post(`payroll`, {
          signIn,
          signOut,
          date,
          month,
          adminsId: this.id,
        })
        .then(
          (response) => {
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Added Successfully",
              life: 3000,
            });
            this.getData(this.month);
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
    },
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("print").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(
        this.$htmlPrint(
          stylesHtml,
          prtHtml,
          `${this.$route.params.from} / ${this.$route.params.to}`
        )
      );
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    getPlusOrMines() {
      let total = 0;
      for (const item of this.list) {
        const sub =
          this.getTotalByMintes(item) - this.getWorkHoursToWeakByMintes(item);

        total = total + sub;
      }
      const hours = Math.floor(total / 60);
      const minutes = Math.trunc(total % 60);

      return this.$timeByHours(hours, minutes);
    },
    getMonthWorkHours() {
      let total = 0;
      for (const item of this.list) {
        total = total + this.getWorkHoursToWeakByMintes(item);
      }
      const hours = Math.floor(total / 60);
      const minutes = Math.trunc(total % 60);

      return this.$timeByHours(hours, minutes);
    },
    getHolydays() {
      let sum = 0;
      for (const item of this.list) {
        for (const itm of item) {
          if (itm.holydays) {
            sum = sum + itm.holydays;
          }
        }
      }
      return sum;
    },
    getSickdays() {
      let sum = 0;
      for (const item of this.list) {
        for (const itm of item) {
          if (itm.sickdays) {
            sum = sum + itm.sickdays;
          }
        }
      }
      return sum;
    },
    getDiff(item) {
      if (item && item.signIn && item.signOut) {
        const end = moment(item.signOut, "H:mm");
        const start = moment(item.signIn, "H:mm");

        let diff = Number(end.diff(start, "minutes"));

        const pause = item.pause ? Number(item.pause) : 0;

        let totalMinutes = diff - pause;
        if (item.publicHolidays == 1) {
          totalMinutes = totalMinutes * 2;
        }

        const hours = Math.floor(totalMinutes / 60);
        const minutes = Math.trunc(totalMinutes % 60);
        // if (item.day == '24-04-2023') {
        //   console.log('totalMinutes', totalMinutes);
        //   console.log('hours', hours);
        //   console.log('minutes', minutes);
        //   console.log('minutes2',  minutes > 0 );

        // }

        return this.$timeByHours(hours, minutes);
      }
    },
    getDiffByMintis(item) {
      if (item && item.signIn && item.signOut) {
        const end = moment(item.signOut, "H:mm");
        const start = moment(item.signIn, "H:mm");

        let diff = Number(end.diff(start, "minutes"));
        if (item.publicHolidays == 1) {
          diff = diff * 2;
        }
        const dff = Number(diff);
        const pause = item.pause ? Number(item.pause) : 0;

        const totalMinutes = dff - pause;

        return totalMinutes;
      }
    },
    getTotal(list) {
      let total = 0;
      for (const item of list) {
        if (item.id) {
          total = total + Number(this.getDiffByMintis(item));
        }
      }
      const hours = Math.floor(total / 60);
      const minutes = Math.trunc(total % 60);

      return this.$timeByHours(hours, minutes);
    },
    getTotalByMintes(list) {
      let total = 0;
      for (const item of list) {
        if (item.id) {
          total = total + Number(this.getDiffByMintis(item));
        }
      }

      return total;
    },
    getMinOrPlus(item) {
    
      const total =
        Number(this.getTotalByMintes(item)).toFixed(4) -
        Number(this.getWorkHoursToWeakByMintes(item)).toFixed(4);
      console.log("getMinOrPlus", total);

      const hours = Math.floor(Number(total) / 60);
      console.log("getMinOrPlus hours", hours);
      const minutes = Math.trunc(Number(total) % 60);
      console.log("getMinOrPlus minutes", minutes);

      return this.$timeByHours(hours, minutes);
    },

    getWorkHoursToWeakByMintes(list) {
      const workHoursByMIntes = Number(this.user.workHours) * 60;
      const day = workHoursByMIntes / 7;

      return Number(list.length) * day;
    },
    updateData(val, id, oldValue) {
      this.editId = id;
      this.editValue = val;
      this.value = oldValue;
      this.display = true;
    },
    AddupdateData() {
      this.display = false;
      if (this.editValue == "signIn" || this.editValue == "signOut") {
        const duration = moment(new Date(this.value));

        this.value = duration.format("H:mm");
      }



      let obj={};

      obj[this.editValue]=this.value

      this.$http
        .put(`payroll/${this.editId}`,obj)
        .then(
          (response) => {
            this.value = null;
            this.getData(this.month);

            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Updated Successfully",
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
    },

    getDays(val) {
      this.list = [];
      const monthDays = [];
      const month = moment(val, "MM-YYYY").daysInMonth();

      for (let index = 0; index < month; index++) {
        const duration = moment(val, "MM-YYYY").add(index, "days");

        const day = duration.format("DD-MM-YYYY");
        const dt = moment(day, "DD-MM-YYYY");
        const dayName = dt.format("dddd").substring(0, 3);
        monthDays.push({
          id: null,
          day,
          tag: dayName,
          signIn: null,
          signOut: null,
          pause: null,
          total: null,
          workHours: null,
          publicHolidays: null,
          plusOrMines: null,
          holydays: null,
          sickdays: null,
        });
      }

      let weak = [];
      // console.log(monthDays);

      for (let item of monthDays) {
        const one = this.daysList.find((itm) => itm.date == item.day);
        if (one) {
          item.id = one.id;
          item.signIn = one.signIn;
          item.signOut = one.signOut;
          item.pause = one.pause;
          item.publicHolidays = one.publicHolidays;
          item.holydays = one.holydays;
          item.sickdays = one.sickdays;
        }
        if (item.tag == "Sun") {
          weak.push(item);
          this.list.push([...weak]);
          weak = [];
        } else {
          weak.push(item);
          // console.log(item);
        }
      }

      if (weak.length > 0) {
        this.list.push([...weak]);
        weak = [];
      }
    },
    getData(val) {
      const month = moment(val, "MM-YYYY");

      this.$http
        .post(`payroll/search?limit=1000000000000`, {
          adminsId: this.id,
          month: month.format("MM-YYYY"),
        })
        .then(
          (response) => {
            this.daysList = response.data.data.items;
            this.getDays(val);
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
    },
  },
  created() {
    const aNum = window.prompt("Enter The Password", "");

    // console.log(aNum);
    if (aNum != "555" || aNum == "") {
      this.$router.push("/admin/users");
    } else {
      this.id = this.$route.params.id;
      this.$http.get(`admins/${this.id}`).then(
        (res) => {
          this.user = res.data.data;
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    }
    // this.getDays();
    // this.getData();
  },
  watch: {
    month(val) {
      if (val) {
        this.getData(val);
      }
    },
  },
};
</script>

<style>
.hideHead .p-datatable-thead {
  display: none;
}
</style>
